<template>
  <div id="app">
    <router-view class="view-container" />
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import "@/assets/css/common/base.scss";
@import "@/assets/css/common/reset-element-ui.scss";
</style>
